<template>
  <div class="card">
    <Toast position="top-center" group="info" />
    <h5>Пары токенов</h5>
    <Button
      label="Создать новую пару токенов"
      class="p-button-primary"
      @click="showCreateEditDialog(null)"
    />
    <DataTable
      class="distance_top"
      :value="pairList"
      data-key='id'
      v-model:wallets="pairList"
      responsiveLayout="scroll"
    >
      <Column header='token' field='tokenName'/>
      <Column header='tokenQuote' field='tokenQuoteName'/>
      <Column header='isEnabled' field='isEnabled'/>
      <Column class="bg-white border-none flex flex-nowrap justify-content-end">
        <template #body="slot">
          <Button
            icon="pi pi-pencil"
            class="p-button-rounded p-button-warning m-1"
            @click="showCreateEditDialog(slot.data)"
          />
          <Button
            icon="pi pi-trash"
            class="p-button-rounded p-button-danger m-1"
            @click='showDeleteDialog(slot.data)'
          />
        </template>
      </Column>
    </DataTable>
  </div>

  <Dialog
    v-model:visible='createEditDialog'
    :header="headerText"
    style='width:400px'
    class='p-fluid'
  >
    <label for='token_list'>Выберите необходимый token</label>
    <Dropdown
      class="distance_bottom"
      id='token_list'
      v-model='selectedToken'
      :options='tokenList'
      option-label='name'
    />
    <label for='token_quote_list'>Выберите необходимый tokenQuote</label>
    <Dropdown
      class="distance_bottom"
      id='token_quote_list'
      v-model='selectedTokenQuote'
      :options='tokenList'
      option-label='name'
    />
    <div class="distance_bottom line">
      <Checkbox
        id="is_enabled"
        v-model="isEnabled"
        :binary="true"/>
      <label for="is_enabled">&nbsp;Доступность</label>
    </div>
    <small
      class="block m-1 p-error"
      v-if="tokenEquals"
    >
      Выбранные токены должны отличаться друг от друга
    </small>
    <Button
      :disabled="tokenEquals || selectedToken === null || selectedTokenQuote === null"
      label="Ок"
      class="p-button-primary"
      @click="addOrEdit"
    />
  </Dialog>

  <Dialog
    v-model:visible="deleteDialog"
    header="Удалить актив?"
    :modal="true"
  >
    <div class="confirmation-content">
      <p class="text-center p-error">
        Вы уверены, что хотите удалить?
      </p>
      <p class="text-center">
        <b>{{ modifyPair.tokenName + "/" + modifyPair.tokenQuoteName }}</b>
      </p>
    </div>
    <template #footer>
      <Button
        label="Нет"
        icon="pi pi-times"
        class="p-button-text"
        @click="deleteDialog = false"
      />
      <Button
        label="Да"
        icon="pi pi-check"
        class="p-button-text"
        @click="delPair"
      />
    </template>
  </Dialog>
</template>

<script>
export default {
  name: 'Pair',
  data() {
    return {
      pairList: [],
      tokenList: [],
      selectedToken: null,
      selectedTokenQuote: null,
      isEnabled: true,
      createEditDialog: false,
      modifyPair: null,
      deleteDialog: false
    };
  },
  async mounted() {
    await this.getTokens();
    await this.getPairs();
  },
  methods: {
    async getPairs()
    {
      try{
        const resp = await this.axios.get("api/pairs");
        const pairData = resp.data;
        for (let i = 0; i < pairData.length; i++)
        {
          const item = {};
          item.displayId = i;
          item.id = pairData[i].id;
          let tokenId = pairData[i].token.id;
          let tokenName = pairData[i].token.name;
          let tokenMnemonic = pairData[i].token.mnemonic;
          let tokenQuoteId = pairData[i].tokenQuote.id;
          let tokenQuoteName = pairData[i].tokenQuote.name;
          let tokenQuoteMnemonic = pairData[i].tokenQuote.mnemonic;
          if (tokenId === undefined){
            [tokenId, tokenName, tokenMnemonic] = this.getTokenInfo(pairData[i].token);
            [tokenQuoteId, tokenQuoteName, tokenQuoteMnemonic] = this.getTokenInfo(pairData[i].tokenQuote);
          }

          item.tokenId = tokenId;
          item.tokenQuoteId = tokenQuoteId;
          item.tokenName = tokenName + `(${tokenMnemonic})`
          item.tokenQuoteName = tokenQuoteName + `(${tokenQuoteMnemonic})`
          item.isEnabled = pairData[i].isEnabled;
          this.pairList.push(item);
        }
      }
      catch (e)
      {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Error in pairs api call',
          group: 'info',
          life: 3000,
        });
      }
    },

    async getTokens()
    {
      try{
        const resp = await this.axios.get("api/au_tokens");
        const tokenData = resp.data;
        for (let i = 0; i < tokenData.length; i++)
        {
          const item = {};
          item.displayId = i;
          item.id = tokenData[i].id;
          item.name = tokenData[i].name;
          item.mnemonic = tokenData[i].mnemonic;
          this.tokenList.push(item);
        }
      }
      catch (e)
      {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Error in au_tokens api call',
          group: 'info',
          life: 3000,
        })
      }
    },

    getTokenInfo(iri){
      const id = iri.slice(iri.lastIndexOf('/') + 1);
      const token = this.tokenList.find(item => item.id == id);
      if (token === undefined) return [0,"No name","-"];
      return [token.id, token.name ,token.mnemonic];
    },

    showCreateEditDialog(value)
    {
      if (value === null) {
        this.modifyPair = null;
        this.selectedToken = null;
        this.selectedTokenQuote = null;
        this.isEnabled = true;
      }
      else {
        this.modifyPair = value;
        if (this.tokenList.length !== 0) {
          this.selectedToken = this.tokenList.find(item => item.id === value.tokenId);
          this.selectedTokenQuote = this.tokenList.find(item => item.id === value.tokenQuoteId);
          this.isEnabled = value.isEnabled;
        }
      }
      this.createEditDialog = true;
    },

    addOrEdit()
    {
      if (this.modifyPair === null) {
        this.addPair();
      }
      else {
        this.editPair();
      }
      this.createEditDialog = false;
    },

    async addPair()
    {
      try{
        const headers = {
          accept: "application/json",
          "Content-Type": "application/json",
        }
        const params = {
          token: `api/au_tokens/${this.selectedToken.id}`,
          tokenQuote: `api/au_tokens/${this.selectedTokenQuote.id}`,
          isEnabled: this.isEnabled
        };
        const resp = await this.axios.post("/api/pairs", params, {headers: headers});
        this.showPair(resp.data);
        this.$toast.add({
          severity: 'success',
          summary: 'Successful',
          detail: 'New pair created',
          group: 'info',
          life: 3000,
        });
      }
      catch (e)
      {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Create pair error',
          group: 'info',
          life: 3000,
        })
      }
    },

    showPair(value)
    {
      const item ={};
      item.id = value.id;
      item.displayId = this.pairList.length;
      item.tokenId = this.selectedToken.id;
      item.tokenQuoteId = this.selectedTokenQuote.id;
      item.tokenName = this.selectedToken.name + `(${this.selectedToken.mnemonic})`;
      item.tokenQuoteName = this.selectedTokenQuote.name + `(${this.selectedTokenQuote.mnemonic})`;
      item.isEnabled = this.isEnabled;
      this.pairList.push(item);
    },

    async editPair()
    {
      try{
        const headers = {
          accept: "application/json",
          "Content-Type": "application/merge-patch+json",
        }
        const params = {
          token: `api/au_tokens/${this.selectedToken.id}`,
          tokenQuote: `api/au_tokens/${this.selectedTokenQuote.id}`,
          isEnabled: this.isEnabled
        };
        await this.axios.patch(`/api/pairs/${this.modifyPair.id}`, params, {headers: headers});
        this.showEditPair(this.modifyPair.displayId);
        this.$toast.add({
          severity: 'success',
          summary: 'Successful',
          detail: 'Existing pair edited',
          group: 'info',
          life: 3000,
        });
      }
      catch (e)
      {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Edit pair error',
          group: 'info',
          life: 3000,
        });
      }
    },

    showEditPair(id)
    {
      this.pairList[id].tokenId = this.selectedToken.id;
      this.pairList[id].tokenQuoteId = this.selectedTokenQuote.id;
      this.pairList[id].tokenName = this.selectedToken.name + `(${this.selectedToken.mnemonic})`;
      this.pairList[id].tokenQuoteName = this.selectedTokenQuote.name + `(${this.selectedTokenQuote.mnemonic})`;
      this.pairList[id].isEnabled = this.isEnabled;
    },

    delPair (){
      this.deleteDialog = false;
      try{
        this.axios.delete(`api/pairs/${this.modifyPair.id}`);
        this.pairList.splice(this.modifyPair.displayId, 1);
        this.$toast.add({
          severity: 'success',
          summary: 'Successful',
          detail: 'Pair deleted successfully',
          group: 'info',
          life: 3000,
        });
      }
      catch (e)
      {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Delete pair error',
          group: 'info',
          life: 3000,
        });
      }
    },

    showDeleteDialog(value){
      this.modifyPair = value;
      this.deleteDialog = true;
    }
  },
  computed: {
    tokenEquals() {
      if (this.selectedToken === null || this.selectedTokenQuote === null) return false;
      return this.selectedToken.id === this.selectedTokenQuote.id;
    },
    headerText() {
      return this.modifyPair === null ? "Добавить пару" : "Редактировать пару"
    }
  },
}
</script>

<style scoped>
.distance_top
{
  margin-top:  20px;
}
.distance_bottom
{
  margin-bottom:  20px;
}
.line
{
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
}
</style>